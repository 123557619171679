import BandwagonDotCom from "../../components/BandwagonDotCom";
import React from "react";
import TeamMember from "../../components/TeamMember";

import { StaticImage } from "gatsby-plugin-image";

const ShondraWashington = () => (
  <BandwagonDotCom>
    <TeamMember
      headshot=<StaticImage
        src="../../images/shondra-washington-headshot.png"
        alt="Shondra Washington"
      />
      name="Shondra Washington"
      position="Chief Financial Officer"
    >
      <p>
        Shondra is an emerging fund manager. After working as a technology
        investment banker, she co-founded TBC-Capital, an education-first
        advisory and consulting firm, offering on-demand CFO, business
        development and strategy services for VC-backed companies and small
        businesses with $1M in revenue or more annually. Through her efforts her
        clients have raised over $10M in startup capital. She’s currently based
        in New York and focuses her work in consumer tech, CPG and marketplaces.
      </p>
      <h3>Fun Fact</h3>
      <hr />
      <p>I was a professional dancer in a former life.</p>
      <h3>Why I love Bandwagon</h3>
      <hr />
      <p>
        I love the vision and direction that Bandwagon is going - creating easy
        and innovate touchpoints for the masses to interact with new
        technologies.
      </p>
    </TeamMember>
  </BandwagonDotCom>
);

export default ShondraWashington;
